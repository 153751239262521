html {
  // https://www.color-hex.com/color/b0c4df
  // #b0c4df #b7c9e2 #bfcfe5 #c7d5e8 #cfdbeb #d7e1ef #dfe7f2 #e7edf5 #eff3f8 #f7f9fb
  // rgb(176,196,223) rgba(176,196,223, 0.1)
  background-color: #eff3f8;
  background-size: cover;
  background-repeat: no-repeat;
}

// not edge, ie
@supports (not(-ms-ime-align:auto)) {
  html {
    background-image: url('/assets/logo.svg');
    background-blend-mode: overlay;
  }
}

input::placeholder {
  font-size: smaller;
  font-style: italic;
  color: deepskyblue;
}
